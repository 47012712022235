div.row.menu-entry {
 padding-bottom: 1em;
}
.dish {
  font-weight: bold;
  padding-top: 1em;

}
.description {
}
.extra {

}
.price-full {
  text-align: right
}