html, body {
  font-family: 'Muli', arial, sans-serif;
  /*height: 100%;*/
  background-image: url("../img/norwegian_rose.png");
}

.footer {
  padding: .7em 0 0 0;
  text-align: right;
  color: #ddd;
  background: #a69e94;
  margin: 2em 0 0 0;
}

.footer a {
  color: #ddd;
  text-decoration: none;
  padding: .7em 0 0 0;
}

.footer > div {
  padding-top: .5em;
  /*padding: .5em .5em 0 0;*/
  border-top: 1px dashed #DAD6CE;
}

article {
  background: #FFF;
  padding: 15px 15px 15px 15px;
  margin: 20px 0 0 0;
}

.fb-like-box, .fb-like-box span, .fb-like-box span iframe[style] {
  width: 100% !important;
  height: 65px !important;
}

.main-header {
  display: block;
  margin-top: 20px;
}

#logo {
  padding: .5em 1.5em;
}

#left-line {
  background: url('../img/left-line.svg') no-repeat;
  height: auto;
  padding: 150px 0 0 5px;
  display: inline-block;
  background-position: center center;
}

#right-line {
  background: url('../img/right-line.svg') no-repeat;
  height: auto;
  padding: 150px 0 0 5px;
  display: inline-block;
  background-position: center center;
}

.main-navi {
  padding: 0.5em 0 0.5em 0;
}

.main-navi ul {
  position: relative;
  float: left;
  left: 50%;
  margin: 0 auto;
  padding: 0;
}

.main-navi li {
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  float: left;
  right: 50%;
  margin-right: 10px;
  list-style: none;
}

.main-navi a {
  display: block;
  padding: 9px 20px;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.main-navi a:focus,
.main-navi a:hover,
.main-navi a:active {
  background: #a69e94;
}

.main-navi .current_page_item a {
  background: #80cf25;
  color: #000;
}

.main-content li img {
  width: 100%;
}

#hotsplot-logo {
  text-align: right;
}

#reservationSended {

    background-color: grey;
    margin-bottom: 0px;
  :hover {
    background-color: #5E5E5E;
  }
}

.off-canvas-list i {
  font-size: 1.3em;
  width: 1.25em;
}

.off-canvas-icons {
  float: left;
  margin-top: -3px;
}

/* Menü Leiste Small */
nav.tab-bar {
  border-bottom: 1px solid #c9c9c9;
  background: #A69E94;
}

/* Menü Icon Small */
a.menu-icon span {
  -webkit-box-shadow: 1px 10px 1px 1px #000, 1px 16px 1px 1px #000, 1px 22px 1px 1px #000;
  box-shadow: 0 10px 0 1px #000, 0 16px 0 1px #000, 0 22px 0 1px #000;
  color: #000;
}

a.menu-icon:hover span {
  -webkit-box-shadow: 1px 10px 1px 1px #000, 1px 16px 1px 1px #000, 1px 22px 1px 1px #000;
  box-shadow: 0 10px 0 1px #000, 0 16px 0 1px #000, 0 22px 0 1px #000;
}

section.left-small {
  border-right: none;
  box-shadow: inset 0px 0px 0px black;
}

/* Menü Titel Small */
#nav-title {
  text-align: center;
  font-family: 'Muli', arial, sans-serif;
  font-weight: 400;
  font-size: 1.3em;
  color: rgba(0, 0, 0, 0.7);
}

.speisekarte-menu a {
  background-color: #a69e94;
  border-color: #ddd;
}

.speisekarte-menu a:hover {
  background-color: #a69e94;
  border-color: #ddd;
  color: black;
}

.print {
  /*font-size: 16px;*/
}

a {
  color: black;
}

a:hover {

}

.orbit-container {
  height: 100%;

  .orbit-slide {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
}

.off-canvas-content {
  background: none;
  box-shadow: none;
}

#offCanvasLeft a {
  color: white;
}

.off-canvas {
  height: 100%
}

.hide {
  display: none;
}
